import React, { FC, useMemo, memo } from 'react';
import {
  Box,
  Container,
  Divider,
  Stack,
  Typography,
  Link,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { Links } from 'components/generic/Footer';
import { SocialLinks } from 'components/generic';
import {
  selectFirstAvailableRestaurant,
  selectLegalInfo,
} from 'store/selectors/restaurantSelectors';
import { useMediaQuery } from 'react-responsive';
import { selectPageData } from 'store/selectors/pageSelectors';
import { useTranslation } from 'react-i18next';
import { LegalInfo } from 'types/Restaurant';

const Footer: FC = () => {
  const { LOGO_LIGHT, LOGO_DARK } = useAppSelector<{
    LOGO_LIGHT: string;
    LOGO_DARK: string;
  }>(selectAppSettingsData);
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { address, description, phone } = useAppSelector(
    selectFirstAvailableRestaurant
  );
  const imagesData = useAppSelector(selectPageData('images'));
  const aboutPageData = useAppSelector(selectPageData('about'));
  const legalInfo = useAppSelector(selectLegalInfo);

  function legalBlock(legalInfo: LegalInfo) {
    const labelMapping: Record<keyof LegalInfo, string | null> = {
      service_provider_name: null,
      inn: 'ИНН',
      registration_number: 'ОГРН',
      legal_address: 'Юр. адрес',
      actual_address: 'Факт. адрес',
      legal_phone_number: 'Телефон',
      legal_email: 'Email',
    };

    return (
      <>
        {(Object.keys(legalInfo) as (keyof LegalInfo)[])
          .filter((key) => legalInfo[key])
          .map((key) => (
            <Typography key={key}>
              {labelMapping[key] && `${labelMapping[key]}: `}
              {legalInfo[key]}
            </Typography>
          ))}
      </>
    );
  }

  const logo = useMemo(() => {
    return process.env.REACT_APP_FOOTER_LOGO === 'dark'
      ? LOGO_LIGHT
      : LOGO_DARK;
  }, [LOGO_LIGHT, LOGO_DARK]);

  const color = useMemo(() => {
    if (process.env.REACT_APP_FOOTER_SOCIAL_ICON_COLOR === 'primary') {
      return 'primary';
    }
    return 'secondary';
  }, []);

  if (!imagesData) {
    return null;
  }

  const { dpl_logo, footer_mobile_bg, footer_desktop_bg } = imagesData;

  return (
    <Box
      sx={{
        paddingBottom: { xs: '0px', sm: '30px' },
        paddingTop: { xs: '30px', sm: '60px' },
        minWidth: '100%',
        position: 'relative',
      }}
      component="footer"
    >
      <Box
        sx={{
          backgroundColor: 'primary.main',
          position: 'absolute',
          top: '0px',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '-1',
          opacity: '0.3',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '-1',
        }}
      >
        {footer_mobile_bg && footer_desktop_bg && (
          <Box
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
            component="img"
            src={isMobile ? footer_mobile_bg : footer_desktop_bg}
          />
        )}
      </Box>
      <Container>
        {aboutPageData?.is_contacts ? (
          <>
            <Stack
              sx={{
                alignItems: { xs: 'flex-start', md: 'center' },
                paddingBottom: { xs: '20px', sm: '28px' },
              }}
              direction={{ xs: 'column', md: 'row' }}
            >
              <NavLink to="/">
                <Box component="img" src={logo} />
              </NavLink>
              <Box sx={{ flexGrow: 1, marginBottom: { xs: '20px', sm: '0' } }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '300',
                    lineHeight: '20px',
                    maxWidth: '80%',
                    paddingLeft: { xs: '0', md: '20px' },
                    marginBottom: { xs: '15px', md: '0px' },
                    color: 'text.primary',
                  }}
                >
                  {description}
                </Typography>
              </Box>

              <Stack
                sx={{ alignItems: { xs: 'flex-start', md: 'flex-end' } }}
                spacing={1}
              >
                <Link
                  sx={{
                    fontSize: { xs: '20px', sm: '26px' },
                    lineHeight: '31px',
                    fontWeight: '500',
                    whiteSpace: 'nowrap',
                    color: 'text.primary',
                  }}
                  href={`tel:${phone}`}
                  underline="none"
                >
                  {phone}
                </Link>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '300',
                    lineHeight: '20px',
                    textAlign: { xs: 'left', md: 'right' },
                    color: 'text.primary',
                  }}
                >
                  {address}
                </Typography>
              </Stack>
            </Stack>
            <Divider
              sx={{
                bgcolor: 'primary.main',
                marginBottom: { xs: '12px', sm: '22px' },
              }}
            />
          </>
        ) : (
          <></>
        )}

        <Stack
          sx={{
            marginBottom: { xs: '20px', sm: '30px' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
          }}
          direction={{ xs: 'column', sm: 'row' }}
        >
          <Links />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: { xs: '10px', sm: '0px' },
              alignItems: 'flex-end',
              marginLeft: { xs: '-10px', sm: '0' },
            }}
          >
            <SocialLinks color={color} />
          </Box>
        </Stack>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: { xs: '20px', sm: 0 },
          }}
        >
          <Typography
            sx={{
              textAlign: { xs: 'center', sm: 'left' },
              color: 'text.primary',
            }}
          >
            © 2024 {process.env.REACT_APP_NAME}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              marginBottom: { xs: '20px', sm: '0px' },
            }}
          >
            <Typography
              sx={{
                fontSize: '10px',
                lineHeight: '11px',
                marginBottom: '10px',
                color: 'text.primary',
              }}
            >
              {t('powered_by')}
            </Typography>
            {dpl_logo && (
              <Box
                href="https://deliverplus.ru/lo"
                target="_blank"
                rel="noreferrer"
                component="a"
              >
                <Box component="img" src={dpl_logo} />
              </Box>
            )}
          </Box>
        </Box>

        {legalInfo.length > 0 && (
          <Box
            sx={{
              marginTop: '8px',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            {legalInfo.map((x) => (
              <Box key={x.service_provider_name}>{legalBlock(x)}</Box>
            ))}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default memo(Footer);
